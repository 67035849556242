<template>
  <div class="page">
    <div class="search">
      <div class="search-container">
        <h2 style="font-weight: bold">Search for a registered entity</h2>
        <div>Look for an entitie's detailed information and generate a SKYC report</div>
        <sk-autocomplete class="search-field" v-model="value" :suggestions="dataSource" @select="onSelect"
                         @change="onChange"/>
      </div>
      <div class="main-container">
        <sk-alert style="font-size: 12px" text="Alert text" :dismissible='false'><span/>Generated Reports will be
          automatically stored into
          your <b>Intelliflo Document manager</b></sk-alert>
        <div v-if="companies.length > 0">
          <my-collapse v-for="company in companies" :key="company.companyName" :title="company.companyName"
                       class="display-panel" :collapsed="company.collapsed"
                       style="width:100%; word-break: break-all">
            <div class="collapse-container">
              <div class="company-details">
                <div class="company-number">
                  <div class="label">Company Number</div>
                  <div class="value">{{ company.companyNumber }}</div>
                </div>
                <div class="entity-type">
                  <div class="label">Entity Type</div>
                  <div class="value">{{ company.companyCategory }}</div>
                </div>
                <div class="reg-status">
                  <div class="label">Registration Status</div>
                  <div class="value">{{ company.companyStatus }}</div>
                </div>
              </div>
              <div class="company-address">
                <div class="label">Registered Address</div>
                <div class="value">{{ fullAddress(company) }}</div>
              </div>

              <div style="display: flex;flex-direction: row; justify-content: space-between; margin-top: 35px">
                <div style="width: 200px">
                  <sk-select
                    :value='company.selectedClient'
                    :options='clientOptions'
                    :disabled="company.isGeneratingReport"
                    @input="client => setClient(client, company)"
                    label="Select a client"
                    floatingLabel
                  />
                </div>
                <sk-button v-if='!company.generateReportSuccess'
                           style="width: 170px;align-self: center; padding: 0 !important;cursor:pointer;" color="primary"
                           @click="onGenerate(company)"
                           :loading="company.isGeneratingReport"
                           :disabled="!company.selectedClient"
                           >
                  <link rel="stylesheet"
                        href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@48,400,1,0"/>
                  <span style="margin-right: 5px; margin-left: 0" class="material-symbols-sharp">draft</span>
                  Generate Report
                </sk-button>
                <div v-if="company.generateReportSuccess">
                  <sk-status type="report" status="passed"/>
                </div>
              </div>
            </div>
          </my-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Collapse from '@/components/Collapse'
import * as config from '../environments/environments.json'

export default {
  name: 'Search',
  data: function () {
    return {
      value: '',
      dataSource: [],
      apiUrl: 'https://bizid-backend.skyc.cloud',
      country: 'AU',
      companies: [], // [{abn:'', isGeneratingReport: false, generateReportSuccess: false, collapsed: false, selectedClient: ''}]
      showDetails: false,
      token: '',
      clientOptions: [],
      isError: false
    }
  },
  components: {
    'my-collapse': Collapse
  },
  watch: {
    value: function (val) {
      this.showDetails = false
      if (val.length > 2) {
        const apiUrl = 'https://bizid-backend.skyc.cloud'
        const country = 'UK'
        axios.get(
          `${apiUrl}/search-fast/${country}?query=${encodeURIComponent(val)}`,
          {
            headers: { 'Access-Control-Allow-Origin': '*' },
            timeout: 50000
          }
        ).then(response => {
          this.dataSource = response.data.data.map(item => item.result.companyName)
        }).catch(err => {
          // error msg console log
          console.log(JSON.stringify(err.message))
        })
      }
    }
  },
  methods: {
    setClient (client, company) {
      company.selectedClient = client
    },
    // Comma sep list if there are more than one trading Names
    covertTradingNames (names) {
      if (names) {
        if (names.length === 1) {
          return names[0]
        } else {
          return names.join(', ')
        }
      }
    },
    fullAddress (company) {
      if (company) {
        let address = ''
        if (company.regAddressAddressLine1) {
          address += company.regAddressAddressLine1
        }
        if (company.regAddressAddressLine2) {
          address += ', ' + company.regAddressAddressLine2
        }
        if (company.regAddressPostTown) {
          address += ', ' + company.regAddressPostTown
        }
        if (company.regAddressPostCode) {
          address += ', ' + company.regAddressPostCode
        }
        return address
      }
    },
    // This is the login api to get an access token from Verify
    onGenerate (company) {
      company.isGeneratingReport = true
      const loginUrl = 'https://skyc-intelliflo.azurewebsites.net/api/v2/gb/skyc/login?code=gTiY/rqKPLqco4mK4YN1IN3JlwaBu38th9kwfd3yDYW4CwNWSN9WMw=='
      axios.post(
        loginUrl,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      ).then(res => {
        this.token = res.data.token
        this.getAppId(res.token, company)
      })
    },
    // Get application id with token
    getAppId (token, company) {
      const appUrl = `${config.intellifloApiUrl}/gb/skyc/new-app?code=0RaI286Sjdr9LBq1quHSpK3werR5yax09kq4mH77tL8QaOj4N37/Qg==`
      axios.post(
        `${appUrl}&country=GB&cn=${company.companyNumber}`, null, {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        }
      ).then(response => {
        const appId = response.data.id
        // this.getAllReports()
        this.getReportStatus(appId, company)
      })
    },
    // Get report status with application id
    getReportStatus (appId, company) {
      const statusUrl = `${config.intellifloApiUrl}/gb/skyc/get-status?code=R0ADQiRxnTMGIvfhjCXPHXFA2GW3mha1LbaQI4a420uxIAiCXnwdpg==`
      axios.post(
        statusUrl,
        {
          applicationId: appId
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        }
      ).then(async res => {
        let completed = true
        res.data.data.operationalStatus.forEach(item => {
          if (item.status !== 'complete' && item.item !== 'MapImages') {
            completed = false
          }
        })
        if (
          !completed
          // res.data.data.applicationStatus === 'incomplete' && res.data.data.overallStatus === 'incomplete'
        ) {
          company.isGeneratingReport = true
          // eslint-disable-next-line promise/param-names
          await new Promise(r => setTimeout(r, 5000))
          this.getReportStatus(appId, company)
        } else {
          this.getAllReports(appId, company)
        }
      })
    },

    // Get a list of clients from intelliflo
    getAllClients () {
      this.report = true
      // eslint-disable-next-line no-unreachable
      const url = `${config.intellifloApiUrl}/gb/clients?code=9w246OiH5a2WjlBJrFv4xwyLp1PIpjQMvimiRegoZSBCPCal1xe9gg==`
      const intellifloToken = localStorage.getItem('access_token')
      axios.get(
        url,
        {
          headers: {
            'Access-Control-Allow-Headers': '*',
            'x-api-key': 'app-a100c16-3c95f58aa7904940bd31659b2d37bbb2',
            Authorization: 'Bearer ' + intellifloToken
          }
        }
      ).then(res => {
        const clients = res.data.items
        this.clientOptions = clients.map(client => {
          return {
            label: client.name,
            value: client.id
          }
        })
      })
    },

    createClientPDFDocument (report, company, reportTitle = 'SimpleKYC-Report.pdf') {
      const docsUrl = `${config.intellifloApiUrl}/gb/clients/${company.selectedClient}/documents?code=sXGVupTcF5wZYMCvISwxr8y0kyFtm4goecHryLrpOlvnm7sdm5CgjQ==`
      const intellifloToken = localStorage.getItem('access_token')
      const data = JSON.stringify({
        title: reportTitle,
        description: 'SimpleKYC Report',
        properties: {
          '_fileType.name': 'Bank Statement',
          '_subCategory.name': 'Third Party',
          '_category.name': 'Financials',
          '_status.name': 'Production',
          CustomProperty1: 'Some Value',
          CustomProperty2: 'Some Value'
        },
        object: {
          original_filename: 'Report.pdf'
        },
        linked_entities: [
          {
            id: 1234,
            type: 'Plan'
          }
        ]
      })
      axios.post(
        docsUrl,
        { data },
        {
          headers: {
            'Access-Control-Allow-Headers': '*',
            'x-api-key': 'app-a100c16-3c95f58aa7904940bd31659b2d37bbb2',
            Authorization: 'Bearer ' + intellifloToken
          }
        }
      ).then(res => {
        const uploadDocUrl = res.data.iflo
        this.uploadClientPDFDocument(report, company, uploadDocUrl)
      })
    },

    uploadClientPDFDocument (report, company, uploadDocUrl) {
      const url = `${config.intellifloApiUrl}/uploadPdf?code=IfTv5CfEM3aDRDPwp3R1rk0eNyNF0ymZmVVaU6jYV/7BUbRQGhrbFA==`
      const data = {
        base64: report,
        url: uploadDocUrl
      }
      axios.put(
        url,
        { data },
        {
          headers: {
            'Access-Control-Allow-Headers': '*',
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        company.isGeneratingReport = false
        company.generateReportSuccess = true
      })
    },

    getAllReports: function (appId, company) {
      const allRportsUrl = `${config.intellifloApiUrl}/gb/skyc/get-all-reports?code=SljSWTrgSBzFAs8omrkKw06RhBnXvaHnWDVG8lxvESMtebtJM2nK6w==`
      axios.post(
        allRportsUrl,
        {
          applicationId: appId
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        }
      ).then(res => {
        const hash = res.data.data[0].hash
        this.getReport(appId, company, hash)
      })
    },

    getReport (appId, company, hash) {
      this.isGeneratingReport = true
      const getReportUrl = `${config.intellifloApiUrl}/gb/skyc/get-report?code=hYWMzXIDoEDY/ghsazKYkTzlGr6GJsFkkMmK1V7d9xrgmTVakmKtvQ==`
      axios.post(
        `${getReportUrl}`,
        {
          applicationId: appId,
          hash: hash,
          type: 'base64'
        },
        {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        }
      ).then(res => {
        // this.report = res.data.data.file
        // this.report = this.b64toBlob(this.report)
        const report = res.data.data.file // Buffer.from(res.data.data.file, 'base64')
        this.createClientPDFDocument(report, company)
        company.generateReportSuccess = true
        // window.open(this.report, '_blank')
      }, async err => {
        if (err.response.data.details.errorCode === 'SK-400-15') {
          this.isGeneratingReport = true
          // eslint-disable-next-line promise/param-names
          await new Promise(r => setTimeout(r, 5000))
          this.getReport()
        } else if (err.response.data.details.errorCode === 'SK-400-4') {
          this.isGeneratingReport = false
          this.isError = true
        } else {
          this.isGeneratingReport = false
        }
      })
    },

    onClickSearchButton () {
      const apiUrl = 'https://bizid-backend.skyc.cloud'
      const country = 'UK'
      const searchText = this.value
      axios.get(
        `${apiUrl}/search-fast/${country}?query=${encodeURIComponent(searchText)}`,
        {
          headers: { 'Access-Control-Allow-Origin': '*' },
          timeout: 10000
        }
      ).then(response => {
        const info = response.data.data[0].result
        this.companies.forEach(company => {
          company.collapsed = true
        })
        info.isGeneratingReport = false
        info.generateReportSuccess = false
        info.collapsed = false
        info.selectedClient = ''
        this.companies.push(info)
        this.showDetails = true
        this.getAllClients() // Get a list of clients from intelliflo
      }).catch(err => {
        console.log(JSON.stringify(err.message))
      })
    },
    onSelect () {
      this.onClickSearchButton()
    },
    onChange (value) {
      // this.showDetails = false
    }
  }
}
</script>

<style scoped>
.search-field {
  margin-top: 10px;
  width: 100%;
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
}

.search {
  width: 583px;
  height: 758px;
  /* Background/basic_white 00 */
  background: #FFFFFF;
  /* Border/grey 20 */
  /* border: 1px solid #CCCCCC;*/
  box-sizing: border-box;
  /* Popover/Large */
  /* box-shadow: 0px 0px 8px rgba(6, 15, 26, 0.25);
   border-radius: 8px;*/
}

.search-container {
  padding: 40px 56px 20px;
  background: #FFFFFF;
  /* Bar/Bottom/Large */

  /* box-shadow: 0px -1px 8px rgba(6, 15, 26, 0.15);
   border-radius: 8px 8px 0px 0px;*/
}

.main-container {
  padding: 20px 56px;
}

.collapse-container {
  width: 100%;
}

.company-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.company-address {
  max-width: 250px;
}

.label {
  margin-top: 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: #797979;
}

.value {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1F2731;
}

.display-panel {
  display: flex;
  flex-direction: column;
  align-items: start;
}

#footer {
  bottom: 0px;
  display: inline-block;
}

</style>
