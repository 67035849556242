<template>
  <div id="myCollapse">
    <div class="collapse-header">
      <h4 v-if="collapsed" v-html="title"></h4>
      <ui-icon-button v-if="collapsed" :icon="collapseIcon" @click="toggleCollapse"></ui-icon-button>
      <div
        v-if="!collapsed"
      >
        <div class="header-row">
          <div class="company-name">
            <div class="collapse-label">Legal Entity Name</div>
            <div class="collapse-value">{{ title }}</div>
          </div>
          <ui-icon-button :icon="collapseIcon" @click="toggleCollapse"></ui-icon-button>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

import UiIconButton from 'keen-ui/src/UiIconButton'

export default {
  name: 'my-collapse',
  data () {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: 'Company Name'
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiIconButton
  },
  computed: {
    collapseIcon () {
      return this.collapsed ? 'add' : 'remove'
    }
  },
  methods: {
    toggleCollapse () {
      return (this.collapsed = !this.collapsed)
    }
  }
}
</script>

<style lang="scss">
#myCollapse {
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;

  .collapse-header {
    padding: 10px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: var(--sk-c-black-dark);
      font-size: var(--sk-f-h3-size);
      font-weight: var(--sk-f-weight-extrabold);
    }
  }

  .header-row {
    width: 429px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .collapse-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #797979;
  }

  .collapse-value {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-transform: uppercase;

    /* Text/black 100 */

    color: #060F1A;
  }

  .sk-guidance {

    &__description {
      color: var(--sk-c-black-dark);
    }

    .ui-icon .material-icons {
      &.remove,
      &.add {
        color: var(--sk-c-grey-dark) !important;
      }

      &-button--type-primary.ui-icon-button--color-default {
        background-color: transparent !important;
      }
    }

    &__status-item {
      border-radius: 6px;
      background-color: var(--sk-c-white);
      padding: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }

      > p {
        margin: 0;
      }
    }

    &__content {
      transition: 0.4s max-height ease-in-out, 0.4s margin ease-in-out;
      margin-top: 8px;
      /* FIXME: Dynamic height */
      max-height: 500px;
      overflow: hidden;

      &.collapsed {
        margin-top: 0;
        max-height: 0;
      }
    }
  }
}
</style>
