<template>
  <div class="login-page" style="height: 100%">
    <div class="wrapper" style="min-height: calc(100% - 90px);">
      <a v-if="!login"
         href='https://identity.intelliflo.com/core/connect/authorize?response_type=code&client_id=app-6b69689-acf-3df51df78e7446eea2ee6f9260fdb477&scope=client_data%20client_financial_data%20firm_data%20fund_data%20openid%20profile%20myprofile%20offline_access%20apps&redirect_uri=https%3A%2F%2Fintelliflo-vue-gb.skyc.cloud'>login</a>
      <Search v-if="login"/>
    </div>
<!--    <div id="footer" style="height: 90px">
      <img src="../assets/skyc-logo-small.png"/>
    </div>-->
  </div>
</template>

<script>
import Search from '@/views/Search'
import axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      login: false,
      loginUrl: 'https://identity.intelliflo.com/core/connect/authorize?response_type=code&client_id=app-6b69689-acf-3df51df78e7446eea2ee6f9260fdb477&scope=client_data%20client_financial_data%20firm_data%20fund_data%20openid%20profile%20myprofile%20offline_access%20apps&redirect_uri=https%3A%2F%2Fintelliflo-vue-gb.skyc.cloud'
    }
  },
  components: { Search },
  created () {
    const tokenUrl = 'https://identity.intelliflo.com/core/connect/token'
    const appCode = this.$route.query?.code
    if (appCode) {
      const dataString = `grant_type=authorization_code&code=${appCode}&redirect_uri=https%3A%2F%2Fintelliflo-vue-gb.skyc.cloud`
      axios.post(`${tokenUrl}`,
        dataString,
        {
          headers: {
            'Access-Control-Allow-Headers': '*',
            'Cache-Control': 'no-cache',
            Authorization: 'Basic YXBwLTZiNjk2ODktYWNmLTNkZjUxZGY3OGU3NDQ2ZWVhMmVlNmY5MjYwZmRiNDc3OjlQV18kMnVKcVExR20hUnl6X3UtYmNOX0ByNDcwRA==',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(res => {
        if (res.data?.access_token) localStorage.setItem('access_token', res.data.access_token)
        this.login = true
      })
    } else {
      this.login = false
      //  this.login = true
      window.location.href = this.loginUrl
    }
  }
}
</script>

<style scoped>

</style>
